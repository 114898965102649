.strategies {
    position: relative;
    width: 40%;
    background-image: url(../../assets/stadium.png);
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.strategies h2 {
    padding: 0 6px;
}

.move {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    max-height: 75vh;
    max-width: 30vw;
}

.displayjobs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 6vh;
    font-size: 18px;
}

.multiplejobs {
    width: 45%;
    margin: 1px;
    color: rgb(60, 60, 60);
    font-size: 11px;
    font-weight: bold;
    border-radius: 4px;
    border: 1px solid white;
    background: rgb(236, 236, 236, 0.7);
    box-shadow: -2px 3px 8px 2px rgb(145, 145, 145, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.multiplejobs .remove {
    color: red;
    cursor: pointer;
}

.move img {
    height: auto;
}

.move-item {
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 50%;
    min-height: 160px;
    flex: auto;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.move-item img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    position: relative;
    top: 30px;

}

.move-item h6 {

    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.76rem;
    padding: 0 10px;
    position: absolute;
    top: 10px;

}

.move-item:hover {
    border: 1px solid rgb(150, 195, 216);
    z-index: 10;
}
