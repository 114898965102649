
.popselection2 {
    background-color: whitesmoke;
    height: 89vh;
    display: flex;
    justify-content: space-around;
    padding-top: 5%;
    /* align-items:center; */
    /* background-image: url(../assets/stadium2.png); */

}

.popselection2 h3 {
    color: #1976d2;
    font-size: 20px;
    padding-bottom: 3%;
}

.popselection2 p {
    font-size: 13px;
    font-weight: bold;
    color: black;
    transition: 0.2s;
    padding: 5%;
}

.popselection2 p:hover {
    background-color: darkgray;

}

.popcol1 {
    height: 390px;
    background-color: rgb(121, 126, 143, 0.5);
    padding: 40px;
    border-radius: 10px;
}

.popcol2 {
    height: 390px;
    background-color: rgb(121, 126, 143, 0.5);
    padding: 40px;
    border-radius: 10px;
}

.popcol3 {
    height: 390px;
    background-color: rgb(121, 126, 143, 0.5);
    padding: 40px;
    border-radius: 10px;
}

.popcol4 {
    height: 390px;
    background-color: rgb(121, 126, 143, 0.5);
    padding: 40px;
    border-radius: 10px;
}

@media (max-width: 500px) {


}
