.run-play {
    display: flex;
    height: 100vh;
}

.popup4 {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.headersetting {
    height: 4%;
}

.mainpopup4 {
    display: flex;
    justify-content: space-around;

}

.buttons {
    /* background-color: rgb(44, 44, 44); */
    font-size: 25px;
    width: 10%;
    cursor: pointer;
}

.reset {
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;

}

#undo {
    transition-duration: 0.1s;
    transition-property: transform;
    cursor: pointer;
}

#reset {
    transition-duration: 0.1s;
    transition-property: transform;
    cursor: pointer;
}


.buttons .done {
    background-color: rgb(82, 143, 179);
    cursor: pointer;
}

.buttons .back {
    background-color: rgb(119, 119, 119);
    cursor: pointer;
}
