.sheepopup {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 80;
    right: -1;
    z-index: 10;
    background-color: rgba(129, 127, 127, 0.3);
    /* display:flex;
    justify-content:center;
    align-items:center; */
}

.popupcontentsheet {
    position: absolute;
    right: 1px;
    padding: 20px;
    background-color: rgb(241, 241, 241);
    color: rgb(121, 115, 115);
    max-width: 80%;
    min-height: 100vh;
    min-width: 60%;
    max-height: 100vh;
    max-width: 600px;
    border-radius: 25px;
    filter: drop-shadow(0 0 0.35rem rgb(64, 64, 65));;
}

.created_playlist:hover {
    border-color: rgb(140, 200, 253);
}

.created_playlist {
    width: 400px;
}

.scrol {
    max-height: 90vh;
    overflow-y: scroll;
}
