.strategies-nav-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
}

.strategies-nav-list li {
    list-style: none;
    border: 2px solid #000;
    padding: 4px 15px;
    text-align: center;
    background-color: rgb(7, 41, 95);
}

.strategies-nav-list li:hover {
    background-color: rgb(156, 193, 229);
    cursor: pointer;
    color: rgb(7, 41, 95);
}

.strategy-nav-active {
    background-color: rgb(172, 183, 185);
}
