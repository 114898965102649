.popup2 {

    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

}

.popbody {
    /* position: relative; */
    color: rgb(223, 223, 223);
    width: 100%;
    height: 100vh;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

.pop {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    padding: 0%;
}

.pophead {
    display: flex;
    align-items: center;
    padding-left: 2%;
    height: 40px;
    font-size: 20px;
    position: relative;
}

.popselection {
    background-color: rgb(0 xff, 0 xff, 0 xff);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 28px;
    height: 57vh;

}


.backgroundtitle {
    text-align: center;
    padding: 20px;
    background-color: rgb(7, 41, 95);
    font-size: 20pt;
    font-weight: bold;
}

.select {
    background-color: rgba(156, 193, 229, 0.3);
    padding: 5px;
    color: rgb(212, 211, 211);
    font-weight: bold;
    border-radius: 10px;
    transition: 0.4s;
    font-size: 20px;
    filter: drop-shadow(0 0 0.35rem rgb(0, 0, 0));;
}

.select:hover {
    background-color: rgb(7, 41, 95);

}

.select span {
    font-size: large;
    font-weight: bold;
    padding-left: 10px;
}

.selectpic {
    padding: 10px;

}

.popbuttons {
    background-color: black;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 4%;
    padding-right: 4%;
    position: relative;
}

.popbuttons button {
    width: 100px;
    height: 34px;
    font-size: 17px;
}


@media (max-width: 500px) {

    .popselection {
        flex-direction: column;
        font-size: 35px;
    }

    .selectpic {
        width: 200px;
        height: 120px;
    }

}










