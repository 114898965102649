
.step3 {
    width: 100%;
    height: 100vh;
}

.mainstep3 {
    display: flex;
    flex-direction: column;


}

.backgroundtitle {
    text-align: center;
    background-color: rgb(7, 41, 95);
    font-size: 20pt;
    font-weight: bold;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 73px;

}

.mainstep3_heading {
    background-color: rgb(5, 27, 75);
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 73px;
}

.mainstep3_heading h3 {
    font-size: 20pt;
}

.step3mainpics {
    width: 100%;
    display: flex;
    justify-content: space-around;
    height: 89vh;
    overflow: auto;
}

.step3row {
    display: flex;
    flex-direction: column;
    width: 33%;

}

.picboxste3 {
    border: 5px solid rgb(187, 187, 187);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2%;
    transition: 0.6s;
    background-color: white;
    box-shadow: 5px 10px 15px #888888;


}

.picboxste3 p {
    background-color: rgb(82, 127, 185);
    width: 100%;
    color: white;
    font-size: 12px;
    background-color: rgb(58, 120, 192);
    padding: 4px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
}

.picboxste3:hover {
    border: 5px solid rgb(38, 54, 87);
    /* overflow: hidden; */
}

.picboxste3 img {

    width: 30vw;
    max-height: 11vh;
    max-width: 33vw;
    transform: scaleX(1.02);
    height: auto;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
}

.bottomdef {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    background-color: black;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 5px;
    color: white;
    vertical-align: middle;
}

.bottomdef .buttoncancel {
    width: 12%;
    font-size: 20px;
    color: white;
    border: 1px solid;
    border-radius: 5%;

}

.bottomdef .buttonnext {
    width: 12%;
    font-size: 20px;
    color: white;
    border-radius: 5%;
    border: 1px solid;
    background-color: rgb(60, 113, 194);

}


.popupcontent3 {
    /* position: relative; */
    background-color: white;
    color: rgb(121, 115, 115);
    width: 100%;
    height: 100vh;
    /* max-width: 950px; */
    display: flex;
}

.popup3 {
    display: flex;
    flex-direction: column;
    width: 100%
}

.bottom {
    background-color: black;
    text-align: center;
    align-items: center;
}

.bottom .pagebuttons {
    position: relative;
    top: -10px;
}

.bottom .pagelinks {

}

.bottom .pagelinks span {
    color: white;
    font-size: 20pt;
}

.buttonnext {
    float: right;
}

.buttoncancel {
    float: left;
}

.bottom button {
    display: inline-block;
    height: 30px;
    border-radius: 5px;
    font-size: 20px;
    color: white;
    font-weight: bold;
    padding: 0 35px;
    margin: 0 30px;
}

.headersetting {
    height: 35px;
    background-color: rgb(233, 229, 229);
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    color: rgb(30, 30, 31);


}

.headerset1 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 6%;
}


.headerset2 {
    display: flex;
    justify-content: space-between;
    width: 50%;
    border: 2px solid;
    background-color: rgb(245, 244, 244);
    border-color: rgb(168, 168, 168);
    border-radius: 2px;

}

.headerset3 {
    display: flex;
    justify-content: space-evenly;
    width: 10%;
    font-size: 22px;
    font-weight: bold;
    align-items: center;


}

.headerset4 {
    display: flex;
    justify-content: space-evenly;
    /* width:10%; */
    align-items: center;
    font-size: 25px;
}

.mainpopup3 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 25px;
    color: white;

    /* background-color: rgb(162, 165, 170); */


}

.mainpopup3 h3 {
    color: rgb(58, 120, 192);
    font-weight: 800;
    font-size: 27px;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

.mainpopup3 span {
    color: white;
    padding: 20px;
}

.formationtitle {
    padding: 10px;
}

.mainpics {
    display: flex;
    /* flex-direction:column; */
    justify-content: space-between;
    padding-left: 4%;
    padding-right: 4%;
    height: 88vh;
    background-image: url(../assets/stadium2.png);

}

.picbox3 {
    border: 5px solid;
    margin: 0 30px;
}

.picbox3 img {

    width: 350px;
    max-height: 100px;
    max-width: 400px;
    height: auto;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;

}

.picbox3 p {
    font-size: 12px;
    background-color: rgb(58, 120, 192);
    padding: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;

}

.picbox3 {
    /* width: 200px; */
    /* height:120px;  */
    /* border:2px solid;  */
    /* display:flex; */
    transition: 0.1s;
    box-shadow: 5px 10px 15px #888888;

}

.picbox3:hover {
    background: rgb(145, 193, 241);
    border: 10px solid;
    border-color: rgb(7, 41, 95);

    overflow: hidden;
    opacity: 1;
    z-index: 5;
}

.popup3row {
    display: flex;
    flex-direction: column;
    white-space: pre;
    align-items: center;
    justify-content: space-around;
    /* height:500px; */
    /* background-color:rgb(199, 199, 199);  */
    border-right: 2px solid;
    border-color: rgb(182, 179, 179);
}

.pagelinks {
    margin-left: "50%";
    color: "black";
    display: "flex";
    align-items: "center";
    width: "10%";
    justify-content: "space-evenly";
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;

}


.rightside {
    border: 2px solid;
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: rgb(43, 42, 42);
    padding-bottom: 1%;
    color: white;
}

.rightsidepart1 {
    display: flex;
    flex-direction: column;
    height: 40%;
    padding-top: 10%;

    justify-content: space-between;
}

.part11 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;


    height: 40%;
}

.part11 p {

    font-size: 15px;
    background-color: rgb(92, 90, 90);
    height: 23px;
    padding-left: 5%;
}

.part11 h3 {
    padding-left: 2%;
    padding-bottom: 5%;
}

.part12 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 40%;
}

.part12 h2 {
    transition: 0.2s;
}

.part12 h2:hover {
    background-color: rgb(59, 59, 59);
}

.rightsidepart2 {
    width: 100%;
}

.rightsidepart2 button {
    width: 100%;
    height: 30px;
    border-radius: 4%;
    font-size: 20px;
    color: white;
    font-weight: bold;
}


@media (max-width: 500px) {
    .popupcontent3 {
        /* height:auto; */
        overflow: hidden;
        overflow-y: scroll;
        width: auto;
    }

    .mainpics {

        flex-direction: column;
    }

    .rightside {
        top: 4%;
        height: 96%;
        width: 30%;
        left: 70%;
        position: fixed;
    }

    .popup3row {
        align-items: stretch;
    }

    .headersetting {
        width: 100%;
        position: fixed;
    }

    .mainpopup3 {
        margin-top: 10%;
    }

    .headerset2 {
        width: 40%;
    }

    .headerset1 {
        width: 30%;
    }
}

@media only screen and (min-width: 1025px) {
    .popup3row {
        align-items: center;
    }

    .picboxste3 img {
        width: 20vw;
        max-height: 11vh;
        max-width: 33vw;
    }
}











