.player {
    position: absolute;
    text-align: center;
    user-select: none;
    font-family: 'Courier New', Courier, monospace;

}

.player-image {
    width: 60px;
    height: 50px;
    position: relative;
    clear: both;


}

.small {
    width: 60px;
    clear: both;

}

.big {
    width: 60px;
    clear: both;

}

.topup {

    z-index: 10;
    cursor: pointer;

}

.downpic {
    z-index: -12;
    cursor: pointer;

}

.player-name {
    position: absolute;
    top: 58%;
    left: 37%;
    font-weight: bold;
    display: flex;

    font-size: 11px;
    color: white;
    text-align: center;
    width: 15px;
    cursor: pointer;
    z-index: 100;
}

.player-name-defense {
    position: absolute;
    top: 11%;
    left: 37%;
    font-weight: bold;
    display: flex;

    font-size: 11px;
    color: white;
    text-align: center;
    width: 15px;
    cursor: pointer;
    z-index: 100;
}
