* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.body {
    background-color: rgb(254, 255, 255);
    display: grid;
    width: 100 wh;
    height: 100vh;
    grid-template-rows: 0.7fr 9.3fr;
    grid-template-columns: 1.5fr 9fr;
    background-image: url(../assets/stadium3.png);
}

.header {
    background-color: rgb(7, 41, 95);
    /* grid-column: 1/-8; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: aliceblue;
    font-size: larger;
    padding-left: 5%;
    padding-right: 5%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
}

.headermanage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 20 wh;
}

.mobileview {
    display: none;
    cursor: pointer;
}

/* .picbox3 img{
    border-left: 2px solid;
    border-right: 1px solid;
} */

.headermakeplaybook {
    background-color: rgb(82, 127, 185);
    padding: 20px 15px;
    margin: 15px;
    border-radius: 5px;
    height: 30px;
    width: 110 wh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    filter: drop-shadow(0 0 0.35rem rgb(64, 64, 65));
}

.sidebar {
    background-color: rgb(28, 30, 31);
    grid-row: 1/-1;
    grid-column: 1;
    color: white;
    min-width: 200px;
    min-height: 100vh;
}

.sidebarrow {
    width: 100%;
    background-color: rgb(28, 30, 31);
    height: 40px;
    border-top: 0.5px solid;
    border-color: rgb(53, 53, 53);
    transition: transform 0.2s;
    display: flex;
    /* justify-content:center; */
    align-items: center;
    padding-left: 7%;
    cursor: pointer;
}

.sidebarrow:hover {
    background-color: rgb(51, 51, 49);
}

.sidebarrow p {
    margin-left: 3%;
}

.sidebarsub {
    width: 100%;

    height: 40px;
    margin-top: 2%;

    transition: transform 0.2s;
    margin-left: 15%;
}

.main {
    background-color: rgb(204, 200, 204);
    display: flex;
    height: 100vh;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.main .submain {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 30%;
    align-items: center;
}

.main h4 {
    font-size: larger;
}

.main p {
    color: rgb(60, 61, 61);
}

.main .bicon {
    color: rgb(121, 118, 118);
    width: 100px;
    height: 100px;
}

.main .mainmakeplay {
    background-color: rgb(226, 224, 224);
    padding: 5%;
    height: 100;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
}

.main2 {
    display: flex;
    /* justify-content:center; */
    align-items: center;
    padding: 2%;
    flex-direction: column;
    white-space: pre;
}

.main2row {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 2%;
    background-color: rgba(196, 195, 195);
    height: 40px;
    border-radius: 5px;
    border-top: 3px solid;
    border-color: rgb(82, 127, 185);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

.main2subs p {
    font-size: 16px;

}

.bold {
    font-weight: bold;
    text-align: left;
}

#fileicon {
    color: rgb(139, 43, 43);
}

.main2subs {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    color: rgb(65, 64, 64);
    font-size: x-large;

}

.main2bind {
    display: flex;
    cursor: pointer;
}

@media (max-width: 500px) {
    .body {
        grid-template-rows: 0.7fr 9.3fr;
        grid-template-columns: 10fr;
        /* width: 100%;
        height:100%; */
        background-color: white;
        white-space: pre;
        width: 100 wh;
        height: 100vh;
        font-size: small;
    }

    .main2 {
        padding: 0%;
        padding-top: 2%;
    }

    .mobileview {
        display: flex;
        width: 100%;
        height: 100%;
    }

    .headermanage {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 30%;
    }

    .sidebar {
        display: none;
    }
}

.play1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.created_playlist {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: auto;
    height: auto;
    border: 2px solid;
    margin-left: 0.2%;
    margin-right: 0.2%;
}

.positionsavemain {
    /* position: relative; */
    height: 100%;
    text-align: center;
    background-image: url(./Field.jpg);
    background-size: cover;
}

.picturearea {
    width: 250px;
    height: 150px;
}

.contentarea {
    display: flex;
    flex-direction: column;
}

.headplay1 {
    display: flex;
    width: 100 wh;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 4%;
    white-space: pre;
    font-size: small;
    /* justify-content: space-between; */
}

.headplay1 h2 {
    width: 70%;
    font-weight: bold;
    background-color: aliceblue;
    color: rgb(63, 62, 62);
}

.headplay1 h2 span {
    color: rgb(87, 167, 241);
}

.alert {
    padding: 20px;
    background-color: #f44336;
    color: white;
    position: fixed;
    margin-top: 40%;
    display: none;
}

.closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
    cursor: pointer;
}

.closebtn:hover {
    color: black;
}
