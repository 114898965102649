.headerset5 {
    width: 10%;
    background-color: black;
}

.popup3row {
    border: 0;
    padding: 0;

}

.mainpics {
    padding: 0;
}

.pic img {
    width: 25 wh;
    height: 15vh;
    padding: 0;
    border-left: 3px solid;
    border-right: 1px solid;
    border-color: rgb(150, 151, 151);

}

.pic {
    padding: 0px;
    font-size: 15px;
    margin-left: 10%;


}

.filefolder {
    background-color: rgb(211, 211, 211);
    margin-bottom: 1%;
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: rgb(87, 87, 87);
    font-weight: bold;
}

.headerset2 {
    font-size: 15px;
    white-space: pre;
}

.headerset2 span {
    color: rgb(59, 169, 243);

}

.headerset4 {
    margin-right: 5%;

}

.headersetedit button {
    width: 70px;
    height: 100%;
    background-color: rgb(99, 110, 145);
    font-weight: bold;
    font-size: large;
    word-wrap: break-word;
    white-space: nowrap;
}

.headersetjson button {
    width: 100px;
    height: 110%;
    background-color: rgb(99, 110, 145);
    font-weight: bold;
    font-size: 15px;
    word-wrap: break-word;
    white-space: nowrap;
}

.headerset4 button {
    height: 100%;
    width: 100%;
    background-color: rgb(124, 86, 86);
    font-weight: bold;
    font-size: large;


}

.headerset5 button {
    height: 100%;
    width: 100%;
    background-color: rgb(117, 129, 199);
    font-weight: bold;
    font-size: large
}

.headplayview {
    font-size: 11px;
    white-space: nowrap;
    width: 100%;
    font-weight: bold;
    color: rgb(58, 57, 57);
    word-wrap: break-word;


}

.created_playlist {
    border: 2px solid black;
    margin: 5px;
}
