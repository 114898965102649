.popup {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: -40;
    left: 0;
    background-color: rgb(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.popupcontent {
    position: relative;
    padding: 20px;
    background-color: rgb(241, 241, 241);
    color: rgb(121, 115, 115);
    width: 70%;
    height: 40%;
    max-width: 600px;
    border-radius: 25px;
    filter: drop-shadow(0 0 0.35rem rgb(64, 64, 65));;
}

.content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 100%;
    font-family: Helvetica, sans-serif
}

.buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 60%;
}

.content input {
    width: 60%;
    height: 30px;
}

.buttons button {
    width: 110px;
    height: 30px;
    padding: 10px 25px 25px 25px;
    margin-right: 20px;
    border-radius: 5px;
    font-size: 16px;
    border-color: white;
    color: white;
}

.buttons .cancel {
    background-color: rgb(141, 139, 139);
}

.buttons .continue {
    background-color: rgb(170, 185, 252);
}

.teams {
    display: flex;
    align-items: center;
}
