.mainarea {
    background-color: rgb(59, 59, 59);
}

.mainselection {

    width: 80%;
    height: 90vh;
}

.midsection {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    /* padding-top:5%; */


}

.playpic {

    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;


}

.savepic {
    background-color: #fff;
    width: 60%;
    height: 50vh;
}

.headplay {
    display: flex;
    width: 100 wh;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 4%;
    white-space: pre;
    /* justify-content: space-between; */
}

.headplay p {
    padding-left: 5%;
    padding-right: 5%;
    font-size: 35px;
    font-weight: bold;
}

.headplay h2 {
    font-size: 25px;
    width: 70%;
    font-weight: bold;
    background-color: aliceblue;
    color: rgb(63, 62, 62);
}

.headplay h2 span {

    color: rgb(87, 167, 241);
}

.positionsave {
    position: relative;
    height: 30%;
    text-align: center;
    background-image: url(./Field.jpg);
    background-size: cover;
}

.withplaybook {
    display: flex;
    /* justify-content:space-around; */
    /* align-items: center; */
}

.showplaybooklist {
    display: flex;
    flex-direction: column;
    margin-left: 9%;
    justify-content: center;
    max-width: 62%;
    /* align-items: center; */
    margin-top: -5%;
}

.main2subssave {
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: rgb(90, 90, 102);
    border-radius: 2px;
    border: 2px solid;
    margin-top: 1%;
    margin-left: 35%;
    margin-bottom: 1%;

}

.main2subssave:hover {
    border: 2px solid;
    border-color: rgb(72, 155, 250);
}
