
.position-side {
    display: flex;
    flex-direction: column;
    height: 95vh;
    width: 100%;
    user-select: none;
}

.position {
    position: relative;
    height: 100%;
    text-align: center;
    background-size: cover;
}

.position-side-header {
    width: 100%;

    padding: 2px 0;
    background-color: rgb(27, 27, 27);
    display: flex;
    justify-content: space-between;
}

.position-side-header h3 {
    font-size: 1rem;
    width: 50%;
    color: rgb(13, 123, 187);
    font-weight: 700;
    padding-left: 10px;
    padding-top: 4px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.position-side-header h3 span {
    color: white;
}

.player-list {
    display: flex;
    padding-right: 10px;
    /* max-width: 50%; */
    /* min-width: 60%; */
}

.players-list-item {
    padding: 0 0px;
    width: 38px;
}

.players-list-item div {

    color: white;
    border: 2px solid rgb(156, 193, 229);
    border-radius: 50px;
    text-align: center;
    padding: 4px;
    font-size: 0.8rem;
    background-color: rgb(7, 41, 95);
    width: 32px;
    height: 28px;
    font-weight: bold;
    cursor: pointer;
}

.players-list-item div:hover {

    background-color: rgb(156, 193, 229);
    color: rgb(7, 41, 95);
}

.player-list-selected {
    padding: 0 0px;
    width: 38px;
}

.player-list-selected-item {
    color: white;
    border: 2px solid rgb(1, 161, 1);
    border-radius: 50px;
    text-align: center;
    padding: 0px;
    font-size: 0.8rem;
    background-color: rgb(7, 41, 95);
    width: 32px;
    height: 28px;
    font-weight: bold;
    cursor: pointer;
}

.player-list-selected-item img {
    position: relative;
    top: -15px;
    width: 150%;
}

.player-list-selected-item div {
    position: absolute;
    padding: 4px;
}

.players-selective-item div {
    background-color: yellow;
    color: black;
    border: 2px solid yellow;
}

.center-line {
    position: absolute;
    background-color: blue;
    width: 100%;
    height: 10px;
    top: 50%;
}

.nextButton4 {
    position: absolute;
    width: 30%;
    right: 0px;
    bottom: 0px;
}

.nextButton4 button {
    width: 100%;
    height: 30px;
    border-radius: 4%;
    font-size: 20px;
    color: white;
    font-weight: bold;
}

.list {
    width: 150px;
    height: 200px;
    position: absolute;
    margin-top: -1%;
    margin-left: -0%;
    z-index: 100;
    font-weight: bold;
    font-size: 20px;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;

}

.opposition_box {
    display: flex;
    font-size: 25px;
    color: white;
    font-weight: 900;
    justify-content: start;
    align-items: center;
}

.opposition_toggle {
    width: 10%;
    justify-content: start;
    align-items: center;
    display: flex;
    z-index: 200;
}
